// Gallery.jsx
import React from 'react';
import {StaticImage} from "gatsby-plugin-image";
import SimpleReactLightbox, {SRLWrapper} from "simple-react-lightbox";
import Layout from "../components/layout";

const GalleryAustaliaNineteenEighty = () => {
    return (
        <Layout>
            <a href="/Gallery">Return to Gallery</a>
            <h1>Australia 1980 - 1990</h1>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/Australia1980/1766854_orig.jpg"
                             alt="1989 Sensei Rob Williams, Sensei Jim Gloriod"/><StaticImage
                src="../images/gallery/Australia1980/2207599_orig.jpg" alt="1987 Newcastle training camp"/><StaticImage
                src="../images/gallery/Australia1980/3056742_orig.jpg"
                alt="1989 Don Don P/L - Martial arts shop in Brisbane"/><StaticImage
                src="../images/gallery/Australia1980/3181601_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia1980/3537444_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia1980/360827_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia1980/4549053_orig.jpg"
                alt="1989 Dan/Kyu Grading in Kilcoy"/><StaticImage
                src="../images/gallery/Australia1980/4678448_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia1980/4895274_orig.jpg" alt="1987 Newcastle training camp"/><StaticImage
                src="../images/gallery/Australia1980/4939145_orig.jpg" alt="Sensei Terry Lyon - Perth"/><StaticImage
                src="../images/gallery/Australia1980/5801689_orig.jpg" alt="1987 Newcastle training camp"/><StaticImage
                src="../images/gallery/Australia1980/6063140_orig.jpg"
                alt="1989 Bush training in Stoney creek, Woodford"/><StaticImage
                src="../images/gallery/Australia1980/6771882_orig.jpg"
                alt="Graham Ravey, Peter Oberaker, John Jarvis, Aubrey Brooks"/><StaticImage
                src="../images/gallery/Australia1980/6916426_orig.jpg"
                alt="Sunday bush training - late 80's"/><StaticImage
                src="../images/gallery/Australia1980/8835436_orig.jpg"
                alt="Sensei Graham Ravey - Perth ©Terry Lyon"/><StaticImage
                src="../images/gallery/Australia1980/8905877_orig.jpg"
                alt="1987 New Castle training camp - Sanchin Shime testing - Sensei Ravey, Sensei Higaonna"/><StaticImage
                src="../images/gallery/Australia1980/915651_orig.jpg" alt="1987 Newcastle training camp"/><StaticImage
                src="../images/gallery/Australia1980/955285_orig.jpg" alt="Osu-Gi photo shoot"/><StaticImage
                src="../images/gallery/Australia1980/9591179_orig.jpg" alt="Sensei Graham Ravey - Perth ©Terry Lyon"/>
            </SRLWrapper></SimpleReactLightbox>
        </Layout>
    );
}

export default GalleryAustaliaNineteenEighty;
